<template>
  <External v-if="businessType === 'EXTERNAL'"></External>
  <Internal v-else></Internal>
</template>
<script>
import External from './components/External.vue';
import Internal from './components/Internal.vue';

export default {
  name: 'FaultReporting',

  components: {
    External,
    Internal,
  },

  created() {
    this.businessType = this.$route.query.businessType || 'INTERNAL'; // 业务类型 默认内部 INTERNAL 外部 EXTERNAL
  },
  data() {
    return {
      businessType: 'INTERNAL',
    };
  },
  computed() {},
};
</script>
